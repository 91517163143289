import moment from "moment"
import "moment/locale/sv"
import React from "react"
import { graphql } from "gatsby"
import EventGroups from "../../components/EventGroups/eventGroups"
import MenuBar from "../../components/MenuBar/menuBar"
import Promotions from "../../components/Promotions/promotions"
import Footer from "../../components/Footer/footer"
import BaseLayout from "../../components/Layout/layout"
import "./index.css"

const _ = require("lodash")

const IndexPage = props => {
  const selectedDayStr = _.replace(props.location.pathname, "/sv/", "")
  const selectedDay =
    !selectedDayStr || moment(selectedDayStr).isValid() ? selectedDayStr : ""
  const availableDays = _.uniqWith(
    _.map(props.data.events.edges, edge =>
      moment(edge.node.date).locale("sv").format("YYYYMMDD")
    ),
    _.isEqual
  )

  return (
    <BaseLayout>
      <div>
        {props.data.promotedEvents && (
          <Promotions promotedEvents={props.data.promotedEvents.edges} />
        )}
        <MenuBar
          sports={props.data.sports.edges}
          channels={props.data.channels.edges}
          availableDays={availableDays}
          selectedDay={selectedDay}
        />
        <div className="indexPageContainer">
          <EventGroups
            eventEdges={props.data.events.edges}
            selectedDay={selectedDay}
          />
        </div>
        <Footer links={props.data.allLinks} />
      </div>
    </BaseLayout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query PageQuery {
    sports: allContentfulSport(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          name
          uri
          code
        }
      }
    }
    channels: allContentfulChannel(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          name
          uri
        }
      }
    }
    promotedEvents: allContentfulEvent(
      filter: { promote: { eq: true }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            logoUri
            logoUriNeg
            logo {
              file {
                url
              }
            }
          }
          group {
            name
            logoUri
            logoUriNeg
          }
          sport {
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
        }
      }
    }
    allLinks: allContentfulLink(
      limit: 50
      filter: { node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          label
          url
          section
          order
        }
      }
    }
    events: allContentfulEvent(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
